import * as React from "react";
import {
  Wrapper,
  EventWrapper,
  PdpWrapper,
  DescriptionWrapper,
  DownloaderWrapper,
  PhotosWrapper,
CarrouselWrapper2,
  TitleWrapper,
  ImageWrapper,
  MobileTile
} from "./NYStreetStyle.module.css";
import { GrayTitle } from "./../../../../css/Title.module.css";
import NYStreetStylePdp from "./../../../../res/Affiche/Daze Ghost 2.jpeg";
import Carrousel from "../../../../components/Carrousel";
import Title from "../../../../components/Title";
import Carousel from 'react-multi-carousel';

import NYStreetStyle1 from "../../../../res/New York Street Style/insitu-1.jpg"
import NYStreetStyle2 from "../../../../res/New York Street Style/insitu-2.jpeg"
import NYStreetStyle3 from "../../../../res/New York Street Style/insitu-3.jpeg"
import NYStreetStyle4 from "../../../../res/New York Street Style/insitu-4.jpeg"
import NYStreetStylePdf from "../../../../res/New York Street Style/PresseNY.pdf"
import Tile from "../../../../res/New York Street Style/cover.png"

const isBrowser = typeof window !== "undefined";
const infos ={
  photos: [
    { src: NYStreetStyle1, name: "NYStreetStyle" },
    { src: NYStreetStyle2, name: "NYStreetStyle" },
    { src: NYStreetStyle4, name: "NYStreetStyle" },
  ],
};

const NYStreetStyle = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <div className={EventWrapper}>
        <img className={PdpWrapper} src={Tile} alt="daze"/>
        <div className={DescriptionWrapper}>
        <h1 className={TitleWrapper}>NEW YORK STREET STYLE{/*<h2 style={{paddingLeft: "16px"}}>40 ans de création - Marseille - 2020</h2>*/}</h1>
        <br />
        <img className={MobileTile} src={Tile} alt="daze"/>
        <br />
        <p>
        In the 70’s New York, while artists were taking over the city, they formed around a new culture that was their own, with its own codes, language, style, and 'attitude’. From those mixed codes of ‘ghetto children’ of The Bronx or Brooklyn, they mark the beginning of hip-hop culture, urban movement, clandestine and protesting which include DJing, rap, beatbox, breakdance and graffiti. A carefully studied carelessness for language and style opened a new way to design: casual chic. Hip-hop has played a leading role, major in the history and democratization of sneakers, and since 1985, the Jordan Nike has been their star. Misappropriate by rappers, revisited by fashion, and romanced by luxury, the J1 has become a true pop icon that turned everything upside down. It marks a break between the borders of practices and opens the way for a new artistic and aesthetic avant-garde. The stamped youth from its history finds an echo in many of today’s great creators and artists. This is what the GHOST GALERIE attempts to highlight for its 2022 summer exhibition.</p>
          <br></br>
          <br></br>
          <a className={DownloaderWrapper} href={NYStreetStylePdf}download rel="noopener noreferrer" target="_blank">
              Télécharger le communiqué de presse
          </a>
          <br />
        </div>
      </div>
        
        <div className={PhotosWrapper}>
          <Title title={"Vues in situ"} />
          <div className={CarrouselWrapper2}>
          <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
          </div>
        </div>
        <br />
    </div>
  );
};

export default NYStreetStyle;
