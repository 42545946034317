// extracted by mini-css-extract-plugin
export var CarrouselWrapper2 = "NYStreetStyle-module--CarrouselWrapper2--MBMN9";
export var DescriptionWrapper = "NYStreetStyle-module--DescriptionWrapper--mGJRM";
export var DownloaderWrapper = "NYStreetStyle-module--DownloaderWrapper--pKKvo";
export var EventWrapper = "NYStreetStyle-module--EventWrapper--GPHfF";
export var ImageWrapper = "NYStreetStyle-module--ImageWrapper--S9n3Z";
export var ImagesWrapper = "NYStreetStyle-module--ImagesWrapper--MSDgR";
export var MobileTile = "NYStreetStyle-module--MobileTile--qpBHu";
export var PdpWrapper = "NYStreetStyle-module--PdpWrapper--HVoQO";
export var PhotosWrapper = "NYStreetStyle-module--PhotosWrapper--8gkMU";
export var TitleWrapper = "NYStreetStyle-module--TitleWrapper--KVtiH";
export var Wrapper = "NYStreetStyle-module--Wrapper--dKXkZ";